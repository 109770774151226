* {
    margin: 0px;
    padding: 0px;
}

body {
    font-family: "Poppins", sans-serif !important;
}

.form-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    display: table;
    background: linear-gradient(
            90deg,
            #46d4e2 0%,
            #3fcbda 19%,
            #2bb1c4 49%,
            #0a88a1 88%,
            #007b96 100%
    );
}

.form-wrapper .login-form {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.form-wrapper .login-form .login-page {
    background-color: #fff;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
    border-radius: 10px;
    padding: 25px;
    text-align: center;
    width: 30%;
    margin: 0 auto;
}

.form-wrapper .login-form .login-page .form-group {
    margin: 15px 0px;
}

.form-wrapper .login-form .login-page .form-group input {
    background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(to right, #01fdff, #7a77ff) border-box;
    color: #313149;
    border: 2px solid transparent;
    display: inline-block;
    padding: 10px 15px;
    border-radius: 8px;
}

.form-wrapper .login-form .login-page form button.btn-primary {
    background: linear-gradient(90.12deg, #01fdff 0.1%, #7a77ff 99.91%);
    border: 0;
    padding: 10px 51px;
    border-radius: 8px;
}

.two-fa-setup .login-form .login-page button.btn-primary {
    background: linear-gradient(90.12deg, #01fdff 0.1%, #7a77ff 99.91%);
    border: 0;
    padding: 10px 51px;
    border-radius: 8px;
}

.form-wrapper .login-form .login-page .form-group p {
    color: red;
    text-align: left;
}

.form-wrapper .login-form .login-page h3 {
    font-weight: 500;
    font-style: normal;
    font-size: 30px;
    line-height: normal;
    letter-spacing: 1px;
    margin: 0;
    padding: 0;
    padding-bottom: 5px;
}

.form-wrapper .login-form .login-page p,
.form-wrapper .login-form .login-page a {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 1.8;
    letter-spacing: 1px;
    margin: 0;
    padding: 0;
}

.form-wrapper .login-form .login-page p.account-register {
    padding: 20px 0px;
}

.text-capitalize {
    text-transform: capitalize;
}

/* --------------------------------------------  dashboard page start css --------------------------------------------*/
.sidebar {
    list-style-type: none;
    width: 240px;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    background-color: #fff;
    box-shadow: 0 0 30px 10px rgba(44, 101, 144, 0.1);
    transition: all 0.65s;
    -webkit-transition: all 0.65s;
}

.sidebar.sb-admin {
    width: 150px;
}

.sidebar ul {
    list-style: none;
    margin: 0;
    padding: 0px 15px;
}

.sidebar ul li {
    padding: 5px 0px;
}

.sidebar ul li a {
    color: #000;
    text-decoration: none;
}

.active {
    color: #3ab1a8 !important;
}

.active span svg path {
    fill: #3ab1a8;
}

.dashboard-data {
    margin-left: 270px;
    margin-top: 25px;
    transition: transform 500ms ease;
}

.dashboard-data.admin-wrapper {
    margin-left: 155px;
}

.dashboard-data .digital-document {
    background: linear-gradient(270deg, #3ab1a8 0%, #b3e4da 100%);
    width: 100%;
    padding: 90px 10px;
    text-align: center;
}

.dashboard-data .Shipping-document {
    background: linear-gradient(
            90deg,
            #46d4e2 0%,
            #3fcbda 19%,
            #2bb1c4 49%,
            #0a88a1 88%,
            #007b96 100%
    );
    width: 100%;
    padding: 20px;
    text-align: center;
}

.banner-28-title {
    font-weight: 600!important;
    padding-bottom: 5px!important;
    font-size: 31px!important;
}

.banner-28-btn {
    padding: 30px;
    display: flex;
    justify-content: space-evenly;
    margin-top: 15px;
}

.banner-31-title {
    font-weight: 600!important;
    padding-bottom: 5px!important;
    font-size: 31px!important;
}

.banner-32-title {
    padding-bottom: 5px!important;
    line-height: 3rem;
}

.banner-31-btn {
    padding: 30px;
    display: flex;
    justify-content: space-evenly;
    margin-top: 15px;
}



.dashboard-data .Shipping-document-banner {
    background: linear-gradient(
            90deg,
            #46d4e2 0%,
            #3fcbda 19%,
            #2bb1c4 49%,
            #0a88a1 88%,
            #007b96 100%
    );
    width: 100%;
    height: 350px;
    padding: 20px;
    text-align: center;
    display: table;
}

.dashboard-data .Shipping-document-banner .shipping-content-details {
    display: table-cell;
    vertical-align: middle;
    align-items: center;
}

.dashboard-data .Shipping-document-banner .shipping-content-details h3 {
    margin: 0;
    padding: 20px 0px;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.dashboard-data .Shipping-document-banner .shipping-content-details p {
    margin: 0;
    padding: 10px 0px;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.dashboard-data .personal-info-section,
.dashboard-data .driver-info-section,
.dashboard-data .setting-section {
    background-color: #fff;
    box-shadow: 0 0 30px 10px rgba(44, 101, 144, 0.1);
    padding: 8px 20px;
    margin: 20px 0px;
    border-radius: 20px;
    position: relative;
}

.dashboard-data .setting-section .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
}

.dashboard-data .upload-photo-section {
    background-color: #fff;
    box-shadow: 0 0 30px 10px rgba(44, 101, 144, 0.1);
    padding: 25px 20px;
    margin: 20px 0px;
    border-radius: 20px;
}

.dashboard-data .document-section h3,
.dashboard-data .personal-info-section h3,
.dashboard-data .driver-info-section h3,
.dashboard-data .delivery-section h3,
.dashboard-data .ship-document-section h3 {
    margin: 0;
    padding: 10px 0px;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.dashboard-data .document-section .form-group,
.dashboard-data .personal-info-section .form-group,
.dashboard-data .driver-info-section .form-group {
    padding: 8px 0px;
}

.dashboard-data .document-section .form-group input,
.dashboard-data .personal-info-section .form-group input,
.dashboard-data .driver-info-section .form-group input {
    display: -webkit-flex;
}

.dashboard-data .personal-info-section button.btn-success,
.dashboard-data .driver-info-section button.btn-success,
.dashboard-data .setting-section button.btn-success {
    background-color: #3fbab2;
    border-color: transparent;
    padding: 8px 40px;
    margin: 15px 0px;
}

.dashboard-data .document-section .digital-document button.btn-success {
    background-color: #e077b7;
    border-color: transparent;
}

.dashboard-data .document-section .digital-document button.btn-success,
.dashboard-data .delivery-section .Shipping-document button.btn-warning,
.dashboard-data .ship-document-section .Shipping-document button.btn-warning {
    padding: 8px 25px;
    margin: 14px 0px 5px;
}

.dashboard-data .delivery-section .Shipping-document img {
    width: 40%;
    display: block;
    margin: 20px auto;
}

.dashboard-data .delivery-section-banner {
    width: 100%;
    display: block;
    margin: 20px auto;
}

.dashboard-data .upload-photo-section .croppy-img {
    width: 250px;
    height: 250px;
    position: relative;
    margin-left: 35px;
}

.dashboard-data .upload-photo-section .croppy-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.dashboard-data .upload-photo-section .image-upload {
    margin: auto;
    width: 200px;
    height: 50px;
    color: white;
    border-radius: 10px;
    background-color: black;
    text-align: center;
    cursor: pointer;
}

.dashboard-data .upload-photo-section #input[type="file"] {
    opacity: 0;
    width: 250px;
    height: 250px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
    cursor: pointer;
}

.dashboard-data .photo-upload-details p {
    margin: 0;
    padding: 10px 0px;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.select-type #inputOne + label {
    background: url("./../images/motorcycle.png");
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
}

.select-type #inputTwo + label {
    background: url("./../images/semi-truck.png");
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
}

.select-type #inputThree + label {
    background: url("./../images/car.png");
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
}

.select-type #inputFour + label {
    background: url("./../images/trailer.png");
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
}

.select-type #inputFive + label {
    background: url("./../images/semi-truck.png");
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
}

.select-type input[type="checkbox"] {
    display: none;
}

.select-type input[type="checkbox"] + label {
    position: relative;
    display: block;
    border: solid 1px #e5e5e5;
    box-shadow: 0 0 1px #fff;
    cursor: pointer;
    height: 100px;
    width: 100px;
}

.select-type.user-details-type input[type="checkbox"] + label {
    position: relative;
    display: block;
    border: solid 1px #e5e5e5;
    box-shadow: 0 0 1px #fff;
    cursor: pointer;
    height: 80px;
    width: 80px;
}

.select-type input[type="checkbox"]:checked + label {
    border: 1px solid #38a89c;
    box-shadow: 0 0 1px #38a89c;
    background-color: #cbedea !important;
}

.select-type input[type="checkbox"]:checked + label::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 28px;
    color: #38a89c;
    content: "✔";
    line-height: 1;
    margin: auto;
    text-align: center;
    height: 1em;
}

.select-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

.select-type .select-whitical {
    width: 50%;
    display: flex;
    align-items: center;
    margin: 10px 0px;
}

.select-type .select-whitical p {
    margin: 0;
    padding: 10px 0px;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.5px;
    padding-left: 15px;
}

.processing-section {
    padding: 0 0 80px 0;
}

.processing-section .processing-title {
    max-width: 650px;
    margin: 0 auto 25px;
    border-bottom: 1px solid #b6bfcc;
}

.processing-section .processing-title h3 {
    margin: 0;
    padding: 10px 0px 0px;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-align: center;
}

.processing-section .processing-title p {
    margin: 0;
    padding: 25px 0px 15px;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-align: center;
}

.processing-section .processing-section-title,
.processing-section .summary-section,
.processing-section .payment-section {
    background-color: #fff;
    box-shadow: 0 0 30px 10px rgb(44 101 144 / 10%);
    margin: 20px 0px;
    border-radius: 20px;
}

.processing-section .processing-section-title p,
.processing-section .summary-section p,
.processing-section .payment-section p {
    margin: 0;
    padding: 15px 30px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.5px;
    border-bottom: 1px solid #b6bfcc;
}

.processing-section .processing-section-title ul,
.processing-section .summary-section ul {
    list-style: none;
    margin: 0;
    padding: 15px 30px;
}

.processing-section .processing-section-title ul li {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
    position: relative;
    padding-left: 25px;
}

.processing-section .summary-section ul li {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
    position: relative;
}

.processing-section .summary-section ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 30px;
}

.summary-section ul.processing {
    border-bottom: 1px solid #b6bfcc;
}

.processing-section .processing-section-title ul li::after {
    position: absolute;
    left: 0;
    top: 3px;
    width: 15px;
    height: 15px;
    background-color: #f44e6f;
    content: "";
    border-radius: 10px;
}

.summary-section h6 {
    padding: 0px 0px 25px;
    text-align: center;
}

.summary-section h6 img {
    width: 45px;
    padding-right: 15px;
}

.container .row .col .btn.btn-primary {
    margin: 48px auto 0px;
    width: 15%;
    padding: 10px 0px;
    display: block;
    border-radius: 5px;
}

.processing-section .payment-section .stripe-payment {
    margin: 0;
    padding: 0px 30px 15px;
}

.processing-section .payment-section .stripe-payment h3 {
    margin: 0;
    padding: 10px 0px 0px;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.processing-section .payment-section .form-group {
    margin: 15px 0px;
}

.processing-section .payment-section .form-group input {
    height: 48px;
}

.processing-section .payment-section .btn.btn-primary {
    width: 30% !important;
    border-radius: 8px !important;
    margin: 15px auto !important;
}

.processing-section .payment-section ul li {
    margin: 0px 25px;
    padding: 15px 0px 0px;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.processing-section .payment-section .custom_radio {
    padding: 15px 30px 25px;
}

.processing-section .payment-section .custom_radio input[type="radio"] {
    display: none;
}

.processing-section .payment-section .custom_radio input[type="radio"] + label {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    margin-right: 2em;
    cursor: pointer;
    line-height: 20px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    color: #000;
}

.processing-section
.payment-section
.custom_radio
input[type="radio"]
+ label:before,
.processing-section
.payment-section
.custom_radio
input[type="radio"]
+ label:after {
    content: "";
    position: absolute;
    top: 5;
    left: 0;
    width: 20px;
    height: 20px;
    text-align: center;
    color: white;
    font-family: Times;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border: 2px solid #3fbab2;
}

.processing-section
.payment-section
.custom_radio
input[type="radio"]
+ label:before {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em white;
}

.processing-section
.payment-section
.custom_radio
input[type="radio"]:checked
+ label:before {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em #3fbab2;
}

.usps-tracking {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px;
}

.usps-tracking img {
    width: 64px;
    margin: 0px 20px;
}

.process-price {
    min-width: 0 !important;
}

.usps-tracking label.courier-name,
.usps-tracking span.courier-price {
    min-width: 180px;
}

.usps-tracking [type="radio"]:checked,
.usps-tracking [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

.usps-tracking [type="radio"]:checked + label,
.usps-tracking [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}

.usps-tracking [type="radio"]:checked + label:before,
.usps-tracking [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: -12px;
    width: 20px;
    height: 20px;
    border: 2px solid #3fbab2;
    border-radius: 100%;
    background: #fff;
}

.usps-tracking [type="radio"]:checked + label:after,
.usps-tracking [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 10px;
    height: 10px;
    background: #3fbab2;
    position: absolute;
    top: -7px;
    left: 5px;
    border-radius: 100%;
    transition: all 0.2s ease;
}

.usps-tracking [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.usps-tracking [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.ordertrack-section {
    background-color: #fff;
    box-shadow: 0 0 30px 10px rgb(44 101 144 / 10%);
    margin: 20px 0px;
    border-radius: 20px;
    padding: 25px;
}

.ordertrack-section .form-group {
    margin: 15px 0px;
}

.ordertrack-section .form-group input {
    height: 48px;
}

.document-section .personal-info-section .inline-carousel {
    text-align: center;
    width: 100%;
}

.doc-slider, .doc-slider .inline-carousel {
    min-height: 330px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.unsubscribe-container{
    height:100vh;
    background: linear-gradient( 90deg, #46d4e2 0%, #3fcbda 19%, #2bb1c4 49%, #0a88a1 88%, #007b96 100% );
}
.logo-image-container{
    background: #fff;
}
.detail-container{
    margin-top: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.unsubscribe-container .button-container{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 10%;
}

.unsubscribe-container .button-container a{
    color: inherit;
    text-decoration: none;
}

.desc-container{
    border:1px solid white;
    border-radius: 1rem;
    padding: 2rem 2rem;
    background: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
}
.unsubscribe-desc-container{
    margin-top: 3%;
    font-size:1.125rem;
    opacity: 0.7;
}
.confirmation-question-container{
    text-align: center;
}

.endmodel{
    position: absolute;
    height: 100%;
    width:100%;
    top:0px;
}
.model-background{
    height: 100%;
    width: 100%;
    top:0px;
    background: black;
    opacity: 0.8;
}
.model-design{
    top:0px;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.unsubscribe-title{
    text-decoration: underline;
    text-underline-offset: 0.5rem;
    font-size:2.125rem;
    font-weight: 700;
}

.unsubscribe-desc{
    font-size:1.25rem;
    margin-top:1rem;
    font-weight: 600;
    color:#007b96
}
.unsubscribe-desc-container{
    color:#007b96;
    font-weight:600;
}
.contact-admin-text{
    font-weight: 400;
    color:black;
}
.end-model-description{
    font-size: 1.5rem;
}
.btn-text-login{
    color:#0a88a1;
    border: 0px;
    background: transparent;
    font-weight: 700;
}
.Unsubscribe-description{
    padding: 2.125rem 2rem;
    background: white;
    border-radius: 1rem;
}

@media (min-width: 2000px){
    .doc-slider, .doc-slider .inline-carousel {
        min-height: 320px;
    }
}

@media (min-width: 1200px) and (max-width: 1600px) {
    .doc-slider, .doc-slider .inline-carousel {
        min-height: 340px;
    }
}

@media (min-width: 375px) and (max-width: 1350px) {
    .pdf-view canvas.react-pdf__Page__canvas {
        width: 230px !important;
        height: 150px !important;
    }
}

.pdf-view {
    display: flex;
    justify-content: center;
}

.pdf-view canvas{
    cursor: pointer;
}

.react-pdf__Page__textContent {
    display: none;
}

canvas.react-pdf__Page__canvas {
    width: 315px !important;
    height: 200px !important;
    margin-top: 40px;
}
.scanned_document canvas.react-pdf__Page__canvas {
    height: 220px !important;
    margin-top: 0px;
}

.id_card_dummy canvas.react-pdf__Page__canvas {
    height: 220px !important;
    margin-top: 5px;
}

.id_card_dummy canvas.react-pdf__Page__canvas h3 {
    margin-bottom: 0;
    padding: 10px 0px;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.5px;
}


.print_document canvas.react-pdf__Page__canvas {
    width: 180px !important;
    height: 250px !important;
    margin-top: 0px;
}

.btn-download-link {
    background: transparent;
    border: none;
    margin: 0;
    padding: 10px 0;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #000;
    text-decoration: none;
}

.document-section .personal-info-section .inline-carousel .pdf-one {
    text-align: center;
}

.document-section .personal-info-section .inline-carousel .download-document {
    margin: 0;
    padding: 10px 0px;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #000;
    text-decoration: none;
}

.document-section .personal-info-section .inline-carousel .pdf-one img {
    z-index: 0;
    display: block;
    margin: 0 auto;
    position: relative;
}

.document-section .personal-info-section .inline-carousel .pdf-shipped {
    position: relative;
}

.document-section .personal-info-section .inline-carousel .pdf-shipped::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
}

.document-section .personal-info-section .inline-carousel .pdf-one p {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 70%;
    transform: translate(-50%, -50%);
    z-index: 99;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #000;
    text-decoration: none;
}

.carousel-control-next,
.carousel-control-prev {
    width: auto !important;
    /*z-index: 99 !important;*/
}

.carousel-control-prev-icon {
    background-image: url("./../images/prev.svg") !important;
}

.carousel-control-next-icon {
    background-image: url("./../images/next.svg") !important;
}

/* --------------------------------------------  dashboard page end css --------------------------------------------*/

.alert-message {
    position: fixed;
    right: 10px;
    top: 10px;
    z-index: 1;
}

.alert-success {
    background-color: #4bb543 !important;
    color: #fff !important;
    border-color: transparent;
}

.btn-close {
    background: transparent url("./../images/close.svg") center/1.5em auto no-repeat !important;
    width: 20px !important;
    height: 20px !important;
    opacity: 1 !important;
}

.alert-dismissible .btn-close {
    top: -3px !important;
    z-index: 0 !important;
}

.btn-close:focus {
    box-shadow: none !important;
}

.card-payment {
    display: block;
    width: 100%;
    padding: 15px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.SubmitButton {
    width: auto;
    display: block;
    margin: 0 auto;
    padding: 12px 55px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #3fbab2;
    color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #3fbab2;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.jupload:not(.input):before {
    background-image: url("./../images/avatar.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    color: transparent;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
}

.upload-photo-section .col .jupload {
    border: 0;
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.upload-photo-section .col .jmodal_content .jupload {
    border: 0;
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 0%;
    left: 0;
    top: 0;
    transform: translate(0, 0);
}

.jupload img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover !important;
    object-position: center;
    z-index: 9999;
    display: block;
    position: absolute;
}

.jmodal {
    height: auto !important;
}

.jtabs .jtabs-headers-container {
    display: none;
}

.jmodal_content .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    padding: 0;
    border: 0 !important;
}

.jmodal_content .row > * {
    width: 33.3% !important;
}

.jbutton.dark {
    background-color: #3fbab2;
}

#__react-alert__ div > div > div {
    background-color: #4bb543 !important;
    color: #fff !important;
    width: 90% !important;
    margin: 0px 50px !important;
}

#__react-alert__ div > div > div > svg {
    fill: #fff !important;
}

.dataTables_wrapper .dataTables_length,
.dashboard-data #example {
    margin-bottom: 25px;
}

.dashboard-data #example tr td,
.dashboard-data #example tr th {
    vertical-align: middle;
    padding: 10px;
    border: 1px solid #f9f9f9;
}

.dashboard-data #example tr .btn-danger {
    color: #f73164;
    background-color: rgba(247, 49, 100, 0.15);
    padding: 8px 30px;
    border-radius: 10px;
    border: 1px solid transparent;
    min-width: 140px;
}

.btn-off-urgent {
    /*visibility: hidden;*/
    display: none;
    color: #ece5e7;
    background-color: rgba(231, 231, 231, 0.15);
    padding: 8px 30px;
    border-radius: 10px;
    border: 1px solid transparent;
    min-width: 140px;
}

.dashboard-data .loader-dashboard {
    position: absolute;
    left: 75%;
    top: 20%;
    transform: translate(-50%, -50%);
    width: auto;
}

.payment-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
}

.dashboard-data #example tr .btn-success {
    color: #2b7d5e;
    background-color: rgba(46, 255, 176, 0.15);
    padding: 8px 30px;
    border-radius: 10px;
    border: 1px solid transparent;
    min-width: 140px;
}

.dashboard-data #example tr .btn-info {
    color: #9910f2;
    background-color: rgba(153, 16, 242, 0.15);
    padding: 8px 30px;
    border-radius: 10px;
    border: 1px solid transparent;
    min-width: 140px;
}

table.dataTable.stripe tbody tr.odd,
table.dataTable.display tbody tr.odd,
table.dataTable.display tbody tr.odd > .sorting_1,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_1,
table.dataTable.display tbody tr.even > .sorting_1,
table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
    background-color: #ffffff;
}

table.dataTable.no-footer {
    border: 1px solid #f1f1f1;
}

table.dataTable thead th,
table.dataTable thead td {
    border-color: #f1f1f1;
}

.payment-stripe {
    position: relative;
}

.processing-button {
    position: absolute;
    right: 16%;
    top: 120%;
    transform: translate(-16%, -120%);
    width: 160px;
    display: block !important;
    padding: 10px 45px !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    background-color: #3fbab2 !important;
    color: #ffffff !important;
    margin-left: auto !important;
    border-color: #3fbab2 !important;
    margin-bottom: 15px;
    cursor: pointer !important;
}

.shipping-button {
    position: absolute;
    right: 16%;
    top: auto;
    bottom: calc(100% - 520px);
    /* top: 50%;
    transform: translate(-16%, -50%); */
    width: 160px;
    display: block !important;
    padding: 10px 45px !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    background-color: #3fbab2 !important;
    color: #ffffff !important;
    margin-left: auto !important;
    border-color: #3fbab2 !important;
    margin-bottom: 15px;
    cursor: pointer !important;
}

.ordertrack-button {
    width: 160px;
    display: block;
    padding: 10px 45px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    background-color: #3fbab2 !important;
    color: #ffffff;
    margin-left: auto;
    border-color: #3fbab2 !important;
    margin-bottom: 15px;
    cursor: pointer;
}

.admin-loader {
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%, -20%);
}

.ship-loader {
    position: absolute;
    left: 50%;
    margin-left: 350px;
    top: 30%;
    transform: translate(-50%, -20%);
}

.userdetail-loader {
    margin-left: 200px;
}

.MainDiv {
    background-color: #fff;
    box-shadow: 0 0 30px 10px rgba(44, 101, 144, 0.1);
    padding: 20px 15px;
    margin: 20px 0;
    border-radius: 20px;
    overflow-x: auto;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: #ffffff !important;
    border: 1px solid #3fbab2;
    background-color: #3fbab2;
    background: linear-gradient(to bottom, #3fbab2 0%, #3fbab2 100%);
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    color: #ffffff !important;
    border: 1px solid #3fbab2;
    background-color: #3fbab2;
    background: linear-gradient(to bottom, #3fbab2 0%, #3fbab2 100%);
}

.dashboard-data .admin-from .btn-success {
    width: auto;
    display: block;
    padding: 10px 45px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    background-color: #3fbab2;
    color: #ffffff;
    margin-left: auto;
    border-color: #3fbab2;
    margin-bottom: 15px;
}

.order-btn {
    display: flex;
}

.dashboard-data .MainDiv tr td .btn.btn-warning {
    color: #e68402;
    background-color: rgba(255, 141, 0, 0.15);
    padding: 8px 30px;
    border-radius: 10px;
    border: 1px solid transparent;
    min-width: 140px;
}

.stripe-payment .AppWrapper form .form-group {
    margin-bottom: 0px;
}

.stripe-payment .AppWrapper form .StripeElement {
    display: block;
    width: 100%;
    padding: 15px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 25px;
}

p.payment-error {
    font-size: 14px;
    font-weight: 400 !important;
    letter-spacing: 0.5px;
    text-align: left;
    padding: 0 15px !important;
    margin: 0 !important;
    border-bottom: 0px !important;
}

.nav-item a {
    padding: 10px;
}

.invoice-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.invoice-button .btn-info {
    background-color: #3fbab2 !important;
    color: #ffffff;
    border-color: transparent;
    padding: 10px 20px;
    margin: 10px 0;
}

.invoice-button .btn-info:hover {
    color: #ffffff;
}

.license-section .driving-license {
    width: 100%;
    height: 280px;
    border: 1px solid #e1e1e1;
    border-radius: 15px;
    margin: 20px 0;
}

.license-photo-section .photo-license {
    width: 190px;
    height: 200px;
    border: 1px solid #e1e1e1;
    border-radius: 15px;
    margin: 20px auto;
}

.license-photo-section .photo-license img,
.license-section .driving-license img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 15px;
}

.urgent-button {
    float: right;
    width: 150px;
    padding: 9px 10px !important;
    font-size: 18px !important;
    letter-spacing: 0.5px;
}

.urgent-label {
    float: right;
    width: 100px;
    padding: 9px 10px !important;
    font-size: 18px !important;
    letter-spacing: 0.5px;
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    text-align: center;
    border-radius: 0.25rem;
}

.btn-check:active + .btn-info,
.btn-check:checked + .btn-info,
.btn-info.active,
.btn-info:active,
.show > .btn-info.dropdown-toggle,
.btn-check:focus + .btn-info,
.btn-info:focus {
    color: #ffffff !important;
}

.crop-image {
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0;
}

.crop-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

p.error-message {
    font-size: 16px;
    color: #d9233d;
    letter-spacing: 0.5px;
    margin: 20px 0 0 50px;
    font-weight: 500;
}

.avatar-profile {
    position: relative;
}

.upload-pic,
.upload-pic img, .load-avatar {
    position: relative;
    width: 200px;
    height: 200px;
    cursor: pointer;
    object-fit: contain;
    object-position: center;
    margin: 0 auto;
    text-align: center;
}

.upload-pic.no-avatar, .load-avatar {
    border: 2px solid red;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-pic.no-avatar:after {
    content: '?';
    font-size: 100px;
    color: red;
}

.img-crop-box-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 300px;
}

.img-crop-box {
    width: 100%;
}

.img-crop-box img {
    opacity: 1 !important;
}

.img-crop-box ul {
    position: absolute;
    list-style: none;
    top: 0;
    justify-content: center;
    width: 340px;
    padding: 0;
    display: none;
}

.img-crop-box ul li {
    float: left;
}

.img-crop-box ul li:nth-child(1) {
    padding-right: 10px;
}

.img-crop-box ul li:nth-child(2) {
    padding-left: 10px;
}

.img-crop-box ul li:nth-child(3) {
    display: none;
}

.img-crop-action {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 8px 16px;
}

.tool-button-name {
    background: #3fbab2;
    color: #ffffff;
    border: none;
    width: 100px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.img-crop-tool {
    display: flex;
}

.crop-title {
    display: flex;
    justify-content: space-between;
}

.img-crop-tool .tool-button, .tool-close {
    background: #3fbab2;
    border: none;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    border-radius: 4px;
    color: #ffffff;
}

.img-crop-tool .tool-button:focus, .tool-button-name:focus, .tool-close:focus {
    box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}

.tool-button:disabled, .tool-button-name:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.img-crop-tool svg, .tool-close svg {
    width: 24px;
    height: 24px;
    fill: #ffffff;
}

.no-avatar-crop {
    width: 300px;
    height: 300px;
    border: 2px solid red;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.no-avatar-crop:after {
    content: '?';
    font-size: 100px;
    color: red;
}

.no-avatar-crop.load-avatar:after {
    content: '?';
    font-size: 100px;
    color: red;
    display: none;
}

.avatar-btn {
    margin: 10px auto 0;
    text-align: center;
}

.avatar-btn .btn-outline-primary {
    background-color: transparent;
    border-color: #3fbab2;
    padding: 5px 20px;
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #3fbab2;
    font-weight: 500;
}

.avatar-btn .btn-primary {
    background-color: #3fbab2;
    border-color: #3fbab2;
    padding: 5px 20px;
    font-size: 17px;
    letter-spacing: 0.5px;
    color: #ffffff;
    font-weight: 500;
    margin-left: 10px;
}

.btn-download-hs {
    border-radius: 25px !important;
    padding: 10px 13px !important;
    font-size: 16px !important;
}

.avatar-btn button.btn-check:active + .btn-outline-primary,
.avatar-btn button.btn-check:checked + .btn-outline-primary,
.avatar-btn button.btn-outline-primary.active,
.avatar-btn button.btn-outline-primary.dropdown-toggle.show,
.avatar-btn button.btn-outline-primary:active,
.avatar-btn button.btn-outline-primary:hover,
.avatar-btn button.btn-primary:hover,
.avatar-btn button.btn-check:focus + .btn-outline-primary,
.avatar-btn button.btn-outline-primary:focus,
.avatar-btn button.btn-primary:focus {
    background-color: #3fbab2 !important;
    border-color: transparent !important;
    box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
    color: #fff;
}

.upload-pic input {
    width: 200px;
    height: 200px;
    position: absolute;
    left: -2px;
    top: -2px;
    cursor: pointer;
    opacity: 0;
    z-index: 99;
}

.photoloader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}

.upload-photo-section h3 {
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.5px;
    text-align: center;
}

.prep-load {
    display: flex;
    width: 100%;
    height: 80px;
    align-items: center;
    justify-content: center;
    position: relative;
}

.page-notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    text-align: center;
}

.page-notfound h3 {
    font-weight: 600;
    font-style: normal;
    font-size: 30px;
    line-height: normal;
    letter-spacing: 1px;
    margin: 0;
    padding: 0;
    padding-bottom: 5px;
    text-transform: capitalize;
}

.page-notfound p {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 1.8;
    letter-spacing: 1px;
    margin: 5px 0 20px;
    padding: 0;
}

.page-notfound a.btn {
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    line-height: 1.8;
    letter-spacing: 1px;
    margin: 0;
    padding: 8px 25px;
    color: #ffffff;
    background-color: #3fbab2;
    border-color: transparent;
}

.card-delete {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-delete .card-details img {
    width: 70px;
    margin-right: 15px;
}

.card-delete .card-details p {
    margin: 0;
    padding: 25px 0px 15px;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-align: center;
    border: 0;
}

.processing-section .payment-section .card-delete .card-btn-delete .btn {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    width: 100% !important;
    padding: 8px 35px;
}

.page-notfound span svg {
    width: 80%;
}

.fade .modal-body {
    padding: 40px 20px;
}

.fade .modal-body p {
    font-size: 18px;
    letter-spacing: 0.5px;
    font-weight: 500;
}

.fade .modal-dialog {
    max-width: 650px;
}

.fade .modal-body h3 {
    font-size: 24px;
    letter-spacing: 0.5px;
    font-weight: 600;
}

.fade .modal-body h5 {
    font-size: 20px;
    letter-spacing: 0.5px;
    font-weight: 400;
    padding: 30px 0;
    max-width: 500px;
    margin: 0 auto;
}

.fade .modal-body .modal-btn .btn-primary {
    background-color: #3fbab2;
    border-color: transparent;
    padding: 12px 40px;
    margin: 15px 0px;
}

.fade .modal-body .modal-btn .btn-danger {
    background-color: #dc3545;
    border-color: transparent;
    padding: 12px 40px;
    margin: 15px;
}

.fade .modal-footer {
    border: 0;
}

/* plan renewal section start css */
.paln-renewal {
    background: linear-gradient(137.81deg, #6effd0 36.67%, #039ae1 99.38%);
    width: 100%;
    height: calc(100vh - 0px);
    position: relative;
}

.paln-renewal .renewal-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
}

.paln-renewal .renewal-wrapper ul li:first-child {
    border: 2px solid #3ca8df;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #b5f2f1;
}

.paln-renewal .renewal-wrapper ul li:last-child {
    border: 2px solid #3ca8df;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #b5f2f1;
}

.paln-renewal .renewal-wrapper ul li button,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border-color: transparent !important;
}

#uncontrolled-tab-example-tab-home {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: #000 !important;
    padding: 15px 25px;
    background-color: transparent;
}

#uncontrolled-tab-example-tab-profile {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: #000 !important;
    padding: 15px 25px;
}

.paln-renewal .renewal-wrapper .tab-content .fade {
    margin-top: 30px;
}

.paln-renewal .renewal-wrapper .digital-bundle-price {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px 0;
}

.paln-renewal .renewal-wrapper .digital-bundle-price .price-section {
    border: 2px solid #3ca8df;
    background-color: #b5f2f1;
    text-align: center;
    padding: 35px;
    border-radius: 25px;
    width: 50%;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.best-value-price {
    background-color: #fff !important;
}

/* .paln-renewal .renewal-wrapper .digital-bundle-price .price-section:hover{
  background-color: #FFFFFF;
  -ms-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
  border-radius: 25px !important;
} */

.active-price {
    background-color: #ffffff !important;
    -ms-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    border-radius: 25px !important;
}

.add-class {
    background-color: #b5f2f1 !important;
}

/* #PriceSectionOne,
#PriceSectionThree{
  background-color: #b5f2f1 !important;
} */

.paln-renewal
.renewal-wrapper
.digital-bundle-price
.price-section:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.paln-renewal .renewal-wrapper .digital-bundle-price .price-section:last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.paln-renewal
.renewal-wrapper
.digital-bundle-price
.price-section
span.float-end {
    width: 60px;
    height: 5px;
    background-color: #999999;
    border-radius: 50px;
    margin-right: 40px;
}

.paln-renewal
.renewal-wrapper
.digital-bundle-price
.price-section
span.float-start {
    width: 60px;
    height: 5px;
    background-color: #999999;
    border-radius: 50px;
    margin-left: 40px;
}

.paln-renewal .renewal-wrapper .digital-bundle-price .price-section h3 {
    margin: 0;
    padding: 10px 0 0 0;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #000;
}

.paln-renewal .renewal-wrapper .digital-bundle-price .price-section h5 {
    margin: 0;
    padding: 10px 0 0 0;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #999999;
}

.paln-renewal .renewal-wrapper .digital-bundle-price .price-section h1 {
    margin: 0;
    padding: 10px 0 0 0;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #000000;
}

.paln-renewal .renewal-wrapper .digital-bundle-price .price-section p {
    margin: 0;
    padding: 10px 0 0 0;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #41c46f;
}

.paln-renewal .renewal-wrapper .digital-bundle-price .price-section a {
    margin: 10px 0;
    padding: 8px 35px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #000;
    background-color: #fad92f !important;
    border-color: transparent;
    width: 100%;
}

.paln-renewal
.renewal-wrapper
.digital-bundle-price
.price-section
p.best-price {
    background-color: #41c46f;
    color: #ffffff;
    margin: 0;
    padding: 10px 0 10px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.paln-renewal
.renewal-wrapper
.digital-bundle-price
.price-section
p.best-price
img {
    width: 25px;
}

.review-submit-btn .btn {
    padding: 8px 25px;
    margin: 14px 0px 5px;
}

.btn-dl {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.btn-dl button {
    margin: 0 !important;
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
}

.btn-dl button:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5) !important;
}

/* plan renewal section end css */

@media (max-width: 575.98px) {
    .form-wrapper .login-form .login-page {
        width: 90%;
    }

    .dashboard-data.admin-wrapper {
        margin-left: 0;
    }

    .dashboard-data {
        margin-left: 0px;
        margin-top: 0;
        margin-bottom: 5px;
    }

    .sidebar {
        left: -240px;
    }

    .sidebarshow {
        margin-left: 240px;
    }

    .select-type {
        display: block;
    }

    .select-type .select-whitical {
        width: 100%;
    }

    .dashboard-data .upload-photo-section .croppy-img {
        text-align: center;
        margin: 0 auto;
    }

    .container .row .col .btn.btn-primary,
    .processing-section .payment-section .btn.btn-primary {
        width: 100% !important;
    }

    .processing-section .payment-section .stripe-payment,
    .processing-section .payment-section .custom_radio {
        padding: 15px 25px;
    }

    .dashboard-data .deliver-document {
        width: 100% !important;
    }

    .processing-button {
        position: absolute;
        right: auto;
        top: auto;
        transform: translate(-50%, -10%);
        bottom: -10%;
        left: 50%;
        width: 100%;
    }

    .shipping-button {
        position: absolute;
        right: auto;
        top: auto;
        transform: translate(-50%, -10%);
        bottom: -10%;
        left: 50%;
        width: 100%;
    }

    .invoice-button {
        display: block;
    }

    .invoice-button .btn-info {
        width: 100%;
        margin: 10px 0;
    }

    .page-notfound span svg {
        width: 100%;
        height: auto;
    }

    .page-notfound {
        position: inherit;
        left: auto;
        top: auto;
        transform: translate(0%, 0%);
        width: 100%;
        margin: 20px 0;
    }

    .upload-pic,
    .upload-pic img {
        margin: 0 auto;
    }

    .paln-renewal .renewal-wrapper {
        width: 95%;
        position: inherit;
        left: auto;
        top: auto;
        transform: translate(0px, 0px);
        margin: 0 auto;
    }

    .paln-renewal .renewal-wrapper .digital-bundle-price {
        display: block;
    }

    .paln-renewal .renewal-wrapper .digital-bundle-price .price-section {
        width: 100%;
        border-radius: 25px !important;
        margin: 10px 0;
    }

    .paln-renewal .renewal-wrapper .renewal-image img {
        width: 100% !important;
    }

    .paln-renewal {
        height: auto;
        padding: 15px 0;
    }

    body.sweetalert-overflow-hidden {
        overflow: auto !important;
    }

    .processing-section {
        padding: 25px 0;
    }

    .usps-tracking label.courier-name,
    .usps-tracking span.courier-price {
        min-width: auto;
    }

    .dashboard-menu-icon {
        display: flex;
        align-items: center;
        justify-content: end;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .form-wrapper .login-form .login-page {
        width: 90%;
    }

    .dashboard-data.admin-wrapper {
        margin-left: 0;
    }

    .dashboard-data {
        margin-left: 0px;
        margin-top: 0;
        margin-bottom: 5px;
    }

    .sidebar {
        left: -240px;
    }

    .sidebarshow {
        left: 0;
    }

    .select-type {
        display: block;
    }

    .select-type .select-whitical {
        width: 100%;
    }

    .dashboard-data .upload-photo-section .croppy-img {
        text-align: center;
        margin: 0 auto;
    }

    .container .row .col .btn.btn-primary,
    .processing-section .payment-section .btn.btn-primary {
        width: 100% !important;
    }

    .processing-section .payment-section .stripe-payment,
    .processing-section .payment-section .custom_radio {
        padding: 15px 25px;
    }

    .dashboard-data .deliver-document {
        width: 100% !important;
    }

    .processing-button {
        position: absolute;
        right: auto;
        top: auto;
        transform: translate(-50%, -10%);
        bottom: -10%;
        left: 50%;
        width: 45%;
    }

    .shipping-button {
        position: absolute;
        right: auto;
        top: auto;
        transform: translate(-50%, -10%);
        bottom: -10%;
        left: 50%;
        width: 45%;
    }

    .page-notfound span svg {
        width: 100%;
        height: auto;
    }

    .page-notfound {
        position: inherit;
        left: auto;
        top: auto;
        transform: translate(0%, 0%);
        width: 100%;
        margin: 20px 0;
    }

    .upload-pic,
    .upload-pic img {
        margin: 0 auto;
    }

    .paln-renewal .renewal-wrapper {
        width: 95%;
        position: inherit;
        left: auto;
        top: auto;
        transform: translate(0px, 0px);
        margin: 0 auto;
    }

    .paln-renewal .renewal-wrapper .digital-bundle-price {
        display: block;
    }

    .paln-renewal .renewal-wrapper .digital-bundle-price .price-section {
        width: 100%;
        border-radius: 25px !important;
        margin: 10px 0;
    }

    .paln-renewal .renewal-wrapper .renewal-image img {
        width: 100% !important;
    }

    .paln-renewal {
        height: auto;
        padding: 15px 0;
    }

    body.sweetalert-overflow-hidden {
        overflow: auto !important;
    }

    .processing-section {
        padding: 25px 0;
    }

    #dataTable {
        width: 100% !important;
    }

    .dashboard-menu-icon {
        display: flex;
        align-items: center;
        justify-content: end;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .form-wrapper .login-form .login-page {
        width: 70%;
    }

    .dashboard-data {
        margin-bottom: 5px;
    }

    .select-type {
        display: block;
    }

    .select-type .select-whitical {
        width: 100%;
    }

    .dashboard-data .upload-photo-section .croppy-img {
        text-align: center;
        margin: 0 auto;
    }

    .container .row .col .btn.btn-primary,
    .processing-section .payment-section .btn.btn-primary {
        width: 100% !important;
    }

    .processing-section .payment-section .stripe-payment,
    .processing-section .payment-section .custom_radio {
        padding: 15px 25px;
    }

    .dashboard-data .deliver-document {
        width: 60% !important;
    }

    .processing-button {
        position: absolute;
        right: 30%;
        bottom: -10%;
        transform: translate(-30%, 10%);
        width: 26%;
        top: auto;
    }

    .shipping-button {
        position: absolute;
        right: 30%;
        bottom: -8%;
        transform: translate(-30%, 8%);
        width: 26%;
        top: auto;
    }

    .invoice-button .btn-info {
        margin: 0 10px;
    }

    .page-notfound span svg {
        width: 100%;
        height: auto;
    }

    .page-notfound {
        position: inherit;
        left: auto;
        top: auto;
        transform: translate(0%, 0%);
        width: 100%;
        margin: 20px 0;
    }

    .upload-pic,
    .upload-pic img {
        margin: 0 auto;
    }

    .paln-renewal .renewal-wrapper {
        width: 95%;
        position: inherit;
        left: auto;
        top: auto;
        transform: translate(0px, 0px);
        margin: 0 auto;
    }

    .paln-renewal .renewal-wrapper .digital-bundle-price {
        display: block;
    }

    .paln-renewal .renewal-wrapper .digital-bundle-price .price-section {
        width: 65%;
        border-radius: 25px !important;
        margin: 10px auto;
    }

    .paln-renewal .renewal-wrapper .renewal-image img {
        width: 60% !important;
    }

    .paln-renewal {
        height: auto;
        padding: 15px 0;
    }

    body.sweetalert-overflow-hidden {
        overflow: auto !important;
    }

    .processing-section {
        padding: 25px 0;
    }

    #dataTable {
        width: 100% !important;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .form-wrapper .login-form .login-page {
        width: 50%;
    }

    .select-type {
        display: block;
    }

    .select-type .select-whitical {
        width: 100%;
    }

    .dashboard-data .container-fluid .row .col-12 {
        flex: 0 0 auto;
        width: 50%;
    }

    .dashboard-data.admin-wrapper .container-fluid .row .col-12 {
        flex: 0 0 auto;
        width: 100%;
    }

    .dashboard-data .digital-document div.d-block {
        display: block !important;
    }

    .dashboard-data .digital-document div.d-block img {
        width: 75%;
        padding: 25px 0px 0px;
    }

    .dashboard-data .document-section .digital-document button.btn-success {
        margin-top: 25px;
    }

    .dashboard-data .upload-photo-section .croppy-img {
        width: 100%;
        height: 150px;
    }

    .container .row .col .btn.btn-primary,
    .processing-section .payment-section .btn.btn-primary {
        width: 50% !important;
    }

    .processing-section .payment-section .stripe-payment,
    .processing-section .payment-section .custom_radio {
        padding: 15px 25px;
    }

    .dashboard-data .deliver-document {
        width: 70% !important;
    }

    .dashboard-data .ship-document-section .Shipping-document div.d-block {
        display: block !important;
    }

    .processing-button {
        position: absolute;
        right: 10%;
        top: 110%;
        transform: translate(-10%, -110%);
        width: 22%;
    }

    .shipping-button {
        position: absolute;
        right: 10%;
        top: auto;
        transform: translate(-10%, -130%);
        width: 22%;
    }

    .print-button {
        position: absolute;
        right: 10%;
        top: auto;
        transform: translate(-10%, 0%);
        width: 22%;
    }

    .upgrade-button {
        position: absolute;
        right: 10%;
        top: auto;
        transform: translate(-10%, 0%);
        width: 22%;
    }

    .card-button {
        position: absolute;
        right: 10%;
        top: auto;
        transform: translate(-10%, -270%);
        width: 22%;
    }

    .page-notfound span svg {
        width: 50%;
        /* height: auto; */
    }

    .page-notfound {
        position: inherit;
        left: auto;
        top: auto;
        transform: translate(0%, 0%);
        width: 100%;
        margin: 20px 0;
    }

    .page-notfound {
        position: inherit;
        left: auto;
        top: auto;
        transform: translate(0%, 0%);
        width: 100%;
        margin: 20px 0;
    }

    .upload-pic,
    .upload-pic img {
        margin: 0 auto;
    }

    .paln-renewal .renewal-wrapper {
        width: 95%;
        position: inherit;
        left: auto;
        top: auto;
        transform: translate(0px, 0px);
        margin: 0 auto;
    }

    .paln-renewal .renewal-wrapper .digital-bundle-price .price-section {
        width: 65%;
        border-radius: 25px !important;
        margin: 10px auto;
    }

    .paln-renewal {
        height: auto;
        padding: 15px 0;
    }

    .upload-photo-section .row .col-12 {
        flex: 0 0 auto;
        width: 100% !important;
    }

    .setting-section .row .col-lg-12 {
        flex: 0 0 auto;
        width: 100% !important;
    }

    #dataTable {
        width: 100% !important;
    }
}
.admin-order-btn-group .shipping-button-info {
    margin-bottom: 0 !important;
}
.admin-order-btn-group .btn-font {
    font-size: 14px !important;
}

.shipping-info-section .shipping-button-info .btn-font {
    font-size: 14px !important;
}
.shipping-info-section .shipping-button-info .btn-del {
    background-color: #ffaabc !important;
    color: #3a262b !important;
}
.shipping-info-section .shipping-button-info .btn-apo {
    background-color: #d9c0ff !important;
    color: #3a262b !important;
}

.admin-order-btn-group .shipping-button-info .btn-old-bg {
    background-color: #3fbab2 !important;
    color: white !important;
}

.shipping-info-section .shipping-button-info,
.admin-order-btn-group .shipping-button-info
{
    margin: 15px 0;
}

.shipping-info-section .shipping-btn-d {
    justify-content: end;
    display: flex;
    flex-wrap: wrap;
}

.admin-order-btn-group .shipping-btn-d {
    justify-content: end;
    display: flex;
    flex-wrap: wrap;
}

.shipping-info-section .shipping-button-info .btn-danger,
.admin-order-btn-group .shipping-button-info .btn-danger
{
    color: #f73164;
    background-color: rgba(247, 49, 100, 0.15);
    padding: 8px 30px;
    border-radius: 10px;
    border: 1px solid transparent;
}
.admin-order-btn-group .shipping-button-info .btn-danger {
    background-color: #62b8ff;
    color: #fff;
}

.shipping-info-section .shipping-button-info .btn-success,
.admin-order-btn-group .shipping-button-info .btn-success
{
    color: #2b7d5e !important;
    background-color: rgba(46, 255, 176, 0.15) !important;
    padding: 8px 30px;
    border-radius: 10px;
    border: 1px solid transparent;
    margin: 0 5px;
}

.admin-order-btn-group .shipping-button-info .btn-success {
    background-color: #9781ff !important;
    color: #ffffff !important;
}

.shipping-info-section .shipping-button-info .btn-primary,
.admin-order-btn-group .shipping-button-info .btn-primary,
.shipping-info-section .transtion-history .btn-primary,
.dashboard-data .prep-button .btn-primary {
    color: #007bff;
    background-color: rgba(56, 151, 252, 0.15);
    padding: 8px 30px;
    border-radius: 10px;
    border: 1px solid transparent;
    margin: 0 5px;
}

.admin-order-btn-group .shipping-button-info .btn-primary {
    color: #ffffff;
    background-color: #655aff;
}

.dashboard-data .shipping-info-section {
    background-color: #fff;
    box-shadow: 0 0 30px 10px rgba(44, 101, 144, 0.1);
    padding: 20px;
    margin: 20px 0 0 0;
    border-radius: 20px;
    position: relative;
}

.shipping-info-section form .form-group {
    margin-bottom: 15px;
}

.shipping-info-section .transtion-history {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 15px 0;
}

.shipping-info-section .transtion-history p {
    width: 100%;
    height: calc(100vh - 290px);
    border-radius: 10px;
    border: 1px solid #e1e1e1;
    background-color: #fff;
}

.dashboard-data .shipping-info-section .first-row {
    border: 1px solid #e1e1e1;
    padding: 15px 10px;
    margin: 5px;
}

.dashboard-data .shipping-info-section .second-row {
    background-color: rgba(56, 151, 252, 0.15);
    padding: 15px 10px;
    margin: 5px;
}

.shipping-modal .modal-dialog {
    max-width: 1250px;
}

.shipping-modal .modal-dialog .modal-body {
    padding: 0;
}

.shipping-modal .modal-dialog .modal-body table,
th,
td {
    border: 0;
    border-collapse: collapse;
    font-size: 14px;
    padding: 8px !important;
}

.shipping-modal .modal-dialog .modal-body th,
.shipping-modal .modal-dialog .modal-body td {
    padding: 25px 15px;
}

.shipping-modal .modal-dialog .modal-body td,
.shipping-modal .modal-dialog .modal-body th {
    border: 0;
}

.shipping-modal .modal-dialog .modal-body tr {
    vertical-align: middle;
}

.shipping-modal .modal-dialog .modal-body tr:last-child {
    border-top: 1px solid #e1e1e1;
}

.shipping-modal .modal-dialog .modal-body tr input {
    background-color: #fff;
    border: 2px solid #f73164;
    padding: 10px 0;
}

.trans-refunded {
    background-color: #f2f2f2 !important;
    border: 2px solid #c8c8c8 !important;
    padding: 10px 0;
}

.shipping-modal .modal-dialog .modal-footer .btn-primary {
    color: #007bff;
    background-color: rgba(56, 151, 252, 0.15);
    padding: 8px 30px;
    border-radius: 10px;
    border: 1px solid transparent;
    margin: 0 5px;
    width: 180px;
}

.shipping-modal .modal-dialog .modal-body tr .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.shipping-modal .modal-dialog .modal-body tr .form-group label {
    position: relative;
    cursor: pointer;
}

.shipping-modal .modal-dialog .modal-body tr .form-group label:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #000000;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
}

.shipping-modal
.modal-dialog
.modal-body
tr
.form-group
input:checked
+ label:after {
    content: "";
    display: block;
    position: absolute;
    top: 4px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #000000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.dt-status {
    text-transform: capitalize;
    background: #4ada9b;
    /*For Royal Mail*/
    /*width: 105px;*/
    width: 100px;
    display: flex;
    padding: 3px 10px;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
}

.dt-status.dt-no-bg {
    background: transparent;
}

.status-validate {
    background: #f7b337;
}

.status-valid {
    background: #aeaefe;
}

.status-invalid {
    background: #f1682d;
}

.status-hold {
    background: #cccccc;
}

.status-ready {
    /*background: #4ada9b;*/
    background: #4ada9b;
}

.status-headshot {
    background: rgb(96,189,175);
}

.status-incomplete {
    background: rgb(241, 56, 45);
    color: white;
}

.option-tracking {
    background: rgb(143, 182, 255);
}

.option-usps {
    background: rgb(181, 207, 255);
}

.option-overnight {
    background: rgb(77, 139, 255);
}

.option-2day {
    background: rgb(130, 174, 255);
}

.option-dhl {
    background: rgb(13, 97, 255);
    color: white;
}

.option-nostatus {
    background: #cccccc;
}

/*For Royal Mail*/
/*.option-rm {*/
/*    background: #dc354540;*/
/*}*/

/*For Royal Mail*/
/*.option-rmtracked {*/
/*    background: #dc354575;*/
/*}*/

.bg-jsc {
    background-color: rgb(165, 95, 184) !important;
}

.bg-hs {
    background-color: rgb(96, 189, 175) !important;
}

.bg-ic {
    background-color: rgb(241, 56, 45) !important;
}

.bg-c {
    background-color: rgb(95, 212, 134) !important;
}
.bg-suspended {
    background-color: rgb(247, 217, 17) !important;
}
.bg-unpaid {
    background-color: rgb(247, 217, 17) !important;
}
.dt-tr-selected {
    background-color: rgba(24, 89, 126, 0.55) !important;
}

table.dataTable.display tbody tr.odd > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd > .sorting_1,
table.dataTable.display tbody tr.even>.sorting_1, table.dataTable.order-column.stripe tbody tr.even>.sorting_1{
    background-color: inherit !important;
}

.sc-mt {
    margin-top: 5px;
}

.country-warn input {
    border: 2px solid red;
    border-radius: 5px;
}
@media (min-width: 1200px) and (max-width: 1340px) {
    .form-wrapper .login-form .login-page {
        width: 35%;
    }
}

@media (min-width: 1200px) and (max-width: 1440px) {
    .dashboard-data .upload-photo-section .croppy-img {
        width: 100%;
        height: 250px;
    }

    .card-button {
        position: absolute;
        right: 10%;
        top: auto;
        transform: translate(-10%, -270%);
        width: 22%;
    }

    .user-details-type {
        display: block;
    }

    .select-type.user-details-type .select-whitical {
        width: 100%;
    }
}

@media (min-width: 1441px) and (max-width: 2000.98px) {
    .card-button {
        position: absolute;
        right: 10%;
        top: auto;
        transform: translate(-10%, -270%);
        width: 22%;
    }
}

@media (min-width: 766px) and (max-width: 820px) {
    .banner-28-btn {
        display: block;
    }
    .banner-28-btn-2 {
        margin-top: 5px;
    }
}
@media (min-width: 992px) and (max-width: 1200px) {
    .banner-28-btn {
        display: block;
    }
    .banner-28-btn-2 {
        margin-top: 5px;
    }
}

@media (max-width: 575.98px) {
    .shipping-info-section .shipping-button-info .btn,
    .admin-order-btn-group .shipping-button-info .btn{
        width: 100%;
        margin: 10px 0;
    }
    .shipping-info-section .shipping-btn-d,
    .admin-order-btn-group .shipping-btn-d {
        display: block;
    }
    .banner-28-btn {
        display: block;
    }
    .banner-28-btn-2 {
        margin-top: 5px;
    }

    .shipping-info-section .transtion-history {
        display: block;
    }

    .dashboard-data .shipping-info-section {
        margin: 20px 0;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .shipping-info-section .shipping-button-info .btn,
    .admin-order-btn-group .shipping-button-info .btn {
        width: 100%;
        margin: 10px 0;
    }
    .shipping-info-section .shipping-btn-d,
    .admin-order-btn-group .shipping-btn-d {
        display: block;
    }
    .shipping-info-section .transtion-history {
        display: block;
    }

    .dashboard-data .shipping-info-section {
        margin: 20px 0;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .shipping-info-section .shipping-button-info.text-end .btn,
    .admin-order-btn-group .shipping-button-info .text-end .btn {
        width: 31%;
    }

    .shipping-info-section .shipping-button-info.mt-4 .btn,
    .admin-order-btn-group .shipping-button-info.mt-4 .btn
    {
        width: 47%;
    }
}

.prep-content {
    justify-content: space-between;
}

/*cropper*/
.cropper-container {
    direction: ltr;
    font-size: 0;
    line-height: 0;
    position: relative;
    -ms-touch-action: none;
    touch-action: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cropper-container img {
    display: block;
    height: 100%;
    image-orientation: 0deg;
    max-height: none !important;
    max-width: none !important;
    min-height: 0 !important;
    min-width: 0 !important;
    width: 100%;
}

.cropper-wrap-box,
.cropper-canvas,
.cropper-drag-box,
.cropper-crop-box,
.cropper-modal {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.cropper-wrap-box,
.cropper-canvas {
    overflow: hidden;
}

.cropper-drag-box {
    background-color: #fff;
    opacity: 0;
}

.cropper-modal {
    background-color: #000;
    opacity: 0.5;
}

.cropper-view-box {
    display: block;
    height: 100%;
    outline: 1px solid #39f;
    outline-color: rgba(51, 153, 255, 0.75);
    overflow: hidden;
    width: 100%;
}

.cropper-dashed {
    border: 0 dashed #eee;
    display: block;
    opacity: 0.5;
    position: absolute;
}

.cropper-dashed.dashed-h {
    border-bottom-width: 1px;
    border-top-width: 1px;
    height: calc(100% / 3);
    left: 0;
    top: calc(100% / 3);
    width: 100%;
}

.cropper-dashed.dashed-v {
    border-left-width: 1px;
    border-right-width: 1px;
    height: 100%;
    left: calc(100% / 3);
    top: 0;
    width: calc(100% / 3);
}

.cropper-center {
    display: block;
    height: 0;
    left: 50%;
    opacity: 0.75;
    position: absolute;
    top: 50%;
    width: 0;
}

.cropper-center::before,
.cropper-center::after {
    background-color: #eee;
    content: ' ';
    display: block;
    position: absolute;
}

.cropper-center::before {
    height: 1px;
    left: -3px;
    top: 0;
    width: 7px;
}

.cropper-center::after {
    height: 7px;
    left: 0;
    top: -3px;
    width: 1px;
}

.cropper-face,
.cropper-line,
.cropper-point {
    display: block;
    height: 100%;
    opacity: 0.1;
    position: absolute;
    width: 100%;
}

.cropper-face {
    background-color: #fff;
    left: 0;
    top: 0;
}

.cropper-line {
    background-color: #39f;
}

.cropper-line.line-e {
    cursor: ew-resize;
    right: -3px;
    top: 0;
    width: 5px;
}

.cropper-line.line-n {
    cursor: ns-resize;
    height: 5px;
    left: 0;
    top: -3px;
}

.cropper-line.line-w {
    cursor: ew-resize;
    left: -3px;
    top: 0;
    width: 5px;
}

.cropper-line.line-s {
    bottom: -3px;
    cursor: ns-resize;
    height: 5px;
    left: 0;
}

.cropper-point {
    background-color: #39f;
    height: 5px;
    opacity: 0.75;
    width: 5px;
}

.cropper-point.point-e {
    cursor: ew-resize;
    margin-top: -3px;
    right: -3px;
    top: 50%;
}

.cropper-point.point-n {
    cursor: ns-resize;
    left: 50%;
    margin-left: -3px;
    top: -3px;
}

.cropper-point.point-w {
    cursor: ew-resize;
    left: -3px;
    margin-top: -3px;
    top: 50%;
}

.cropper-point.point-s {
    bottom: -3px;
    cursor: s-resize;
    left: 50%;
    margin-left: -3px;
}

.cropper-point.point-ne {
    cursor: nesw-resize;
    right: -3px;
    top: -3px;
}

.cropper-point.point-nw {
    cursor: nwse-resize;
    left: -3px;
    top: -3px;
}

.cropper-point.point-sw {
    bottom: -3px;
    cursor: nesw-resize;
    left: -3px;
}

.cropper-point.point-se {
    bottom: -3px;
    cursor: nwse-resize;
    height: 20px;
    opacity: 1;
    right: -3px;
    width: 20px;
}

@media (min-width: 768px) {
    .cropper-point.point-se {
        height: 15px;
        width: 15px;
    }
}

@media (min-width: 992px) {
    .cropper-point.point-se {
        height: 10px;
        width: 10px;
    }
}

@media (min-width: 1200px) {
    .cropper-point.point-se {
        height: 5px;
        opacity: 0.75;
        width: 5px;
    }
}

.cropper-point.point-se::before {
    background-color: #39f;
    bottom: -50%;
    content: ' ';
    display: block;
    height: 200%;
    opacity: 0;
    position: absolute;
    right: -50%;
    width: 200%;
}

.cropper-invisible {
    opacity: 0;
}

.cropper-bg {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC');
}

.cropper-hide {
    display: block;
    height: 0;
    position: absolute;
    width: 0;
}

.cropper-hidden {
    display: none !important;
}

.cropper-move {
    cursor: move;
}

.cropper-crop {
    cursor: crosshair;
}

.cropper-disabled .cropper-drag-box,
.cropper-disabled .cropper-face,
.cropper-disabled .cropper-line,
.cropper-disabled .cropper-point {
    cursor: not-allowed;
}

.model-btn {
    display: flex;
    justify-content: space-evenly;
}

@media (max-width: 767px){
    .model-btn {
        display: block;
        text-align: center;
    }
    .model-btn-b {
        margin-top: 10px;
    }
}

@media (min-width: 980px) and (max-width: 1199px){
    .model-btn {
        display: block;
        text-align: center;
    }
    .model-btn-b {
        margin-top: 10px;
    }
}

@media (min-width: 380px) and (max-width: 449px){
    .option-text {
        width: 110px;
        vertical-align: middle;
    }
}

@media (max-width: 379px) {
    .option-text {
        margin-left: 45px;
        margin-right: 7px;
        padding-top: 5px;
    }
}

#upload-photo-section .other-country input::placeholder {
    color: black;
}

#upload-photo-section .other-country input {
    background: #ff00002b;
    border: 3px solid red;
    border-radius: 5px;
}

#upload-photo-section .digital-unpaidOrder {
    min-height: 283px;
    background-image: linear-gradient(to right, #50c9ce , #ddd894);
    text-align: center;
}

#upload-photo-section .digital-unpaidOrder .title {
    padding-top: 135px;
}


#upload-photo-section .price-sections{
    border: 2px solid #3ca8df;
    background-color: #b5f2f1;
    text-align: center;
    /*padding: 50px 20px;*/
    border-radius: 25px;
    width: 50%;
    transition: all 0.5s;
}
#upload-photo-section .price-sections h3 {
    margin: 0;
    padding: 10px 0 0 0;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #000;
}
#upload-photo-section .price-sections h1 {
    margin: 0;
    padding: 10px 0 0 0;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #000000;
}
#upload-photo-section .price-sections p{
    background-color: #41c46f;
    color: #ffffff;
    margin: 0;
    padding: 5px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.5px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#upload-photo-section .price-section1{
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
}
#upload-photo-section .price-section2{
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: 0;
}
#upload-photo-section .paln-renewals {
    background: white;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: start;
}
#upload-photo-section .nav-items{
    border: 2px solid #3ca8df;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #efefef;
}
#upload-photo-section .nav-links{
    color: #000 !important;
    border: none;
    padding: 15px 25px;
}
#upload-photo-section .item1{
    border-radius: 10px 0 0 10px;
    border-right: 0;
    background-color: #efefef;
}
#upload-photo-section .item2{
    border-radius: 0 10px 10px 0;
}
#upload-photo-section .digital-bundle-prices{
    display: flex;
    justify-content: center;
    align-items: center;
}
#upload-photo-section .paln-renewals{
    margin: 20px 0;
}
#upload-photo-section .containers{
    width: 100%;
}
#upload-photo-section .containers2{
    padding: 0 20px;
    width: 40%;
}
#upload-photo-section .summary-section {
    background-color: #fff;
    box-shadow: 0 0 30px 10px rgb(44 101 144 / 10%);
    border-radius: 20px;
}
#upload-photo-section .summary-section p {
    margin: 0;
    padding: 15px 30px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.5px;
    border-bottom: 1px solid #b6bfcc;
}
#upload-photo-section .summary-section ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 30px;
}
#upload-photo-section .summary-section ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 30px;
}
#upload-photo-section .summary-section ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 30px;
    margin: 0;
}
#upload-photo-section .summary-section ul li {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
    position: relative;
}
#upload-photo-section .summary-section ul li {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
    position: relative;
}
#upload-photo-section ul{
    list-style: none;
    margin: 0;
}
#upload-photo-section .summary-section-btn{
    width: 100%;
    height: 60px;
}
#upload-photo-section .summary-section-btn a button{
    border-radius: 50px;
    color: white;
    background-color: #4481ee;
    box-shadow: none;
    border: none;
    width: 200px;
    padding: 5px;
    font-size: 20px;
}
#upload-photo-section .summary-section-btn{
    text-align: center;
}
#upload-photo-section .radios-btn{
    background:linear-gradient(270deg, #b3e4da 40%, #3ab1a8 100%);
    padding: 20px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 20px 0;
}
#upload-photo-section .Checkbox-btn{
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 20px 0;
}
#upload-photo-section .Checkbox-btn input{
    width: 100%;
    height: 30px;
    margin-right: 20px;
    cursor: pointer;
    top: 0;
    left: 0;
}
#upload-photo-section .Checkbox-btn label{
    margin: 0;
    cursor: pointer;
}
#upload-photo-section .rabit-img{
    width: 50%;
}
#upload-photo-section .checkbox-inner{
    width: 50%;
}
#upload-photo-section .personal-info-section {
    background-color: #fff;
    box-shadow: 0 0 30px 10px rgb(44 101 144 / 10%);
    padding: 8px 20px;
    margin: 20px 0;
    border-radius: 20px;
    position: relative;
    width: 100%;
}
#upload-photo-section .personal-info-section h3{
    margin: 0;
    padding: 10px 0px;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-align: left;
}
#upload-photo-section .personal-info-section .form-group {
    padding: 8px 0px;
}
#upload-photo-section form.form {
    padding: 10px;
}
#upload-photo-section .processing-section-title p{
    margin: 0;
    padding: 15px 30px;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-align: left;
    border-bottom: 1px solid #b6bfcc;
}
#upload-photo-section .processing-section-title-inner {
    background-color: #fff;
    box-shadow: 0 0 30px 10px rgb(44 101 144 / 10%);
    margin: 20px 0px;
    border-radius: 20px;
}
#upload-photo-section .commend {
    margin-left: 40px;
    width: 130px;
}
#upload-photo-section .commend span {
    background-color: #fa5873;
    border-radius: 50%;
    padding: 15px;
    color: white;
    font-size: 12px;
    font-weight: bold;
}
#upload-photo-section .Checkbox-btn{
    position: relative;
}
#upload-photo-section .radios-btn1 {
    display: block;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
#upload-photo-section .radios-btn1 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
#upload-photo-section .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    background-color: white;
    border-radius: 3px;
}
#upload-photo-section .radios-btn1 input:checked ~ .checkmark {
    background-color: #2196F3;
}
#upload-photo-section .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
#upload-photo-section .radios-btn1 input:checked ~ .checkmark:after {
    display: block;
}
#upload-photo-section .radios-btn1 .checkmark:after {
    top: calc(50% - 7px);
    left: calc(50% - 7px);
    width: 15px;
    height: 7px;
    border-bottom: 2px solid white;
    border-left: 2px solid white;
    transform: rotate(-45deg);
}
#upload-photo-section .Checkbox-btn label .red {
    border: 2px solid red;
}
#upload-photo-section .Checkbox-btn label .green {
    border: 2px solid #13b100;
}
#upload-photo-section .personal-info-section .form-group .form-label {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.5px;
}
#upload-photo-section .personal-info-section .form-group input {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
}
#upload-photo-section .custom_radio_btn1{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#upload-photo-section .custom_radio_btn {
    display: flex;
    justify-content: start;
    align-items: center;
}
#upload-photo-section1 .idl-fast {
    margin-top: 50px;
}
#upload-photo-section1 .idl-fast h2 {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}
#upload-photo-section1 .idl-fast p {
    font-size: 18px;
    font-weight: 300;
    text-align: center;
}
#upload-photo-section1 .idl-fast hr {
    width: 70%;
    margin: 0 auto;
}
#upload-photo-section .payment-section .payment-header {
    margin: 0;
    padding: 15px 30px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.5px;
    border-bottom: 1px solid #b6bfcc;
}
#upload-photo-section .payment-section{
    background-color: #fff;
    box-shadow: 0 0 30px 10px rgb(44 101 144 / 10%);
    margin: 20px 0px;
    border-radius: 20px;
    width: 60%;
}
#upload-photo-section .StripeElement {
    width: 100%;
    height: 200px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px dashed #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#upload-photo-section .stripe-payment {
    margin: 0;
    padding: 0px 30px 15px;
}
#upload-photo-section .StripeElement--empty p {
    margin: 0;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    width: 100%;
}
#upload-photo-section .price-sections {
    border: 2px solid #3ca8df;
    background-color: #b5f2f1;
    text-align: center;
    height: 200px;
    border-radius: 25px;
    width: 50%;
    transition: all 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* active */

#upload-photo-section .price-section-select{
    margin: 0 -2px;
}
/* right */
#upload-photo-section .active-sectionmain-right{
    border-radius: 0 25px 25px 0;
}
/* left */
#upload-photo-section .active-sectionmain-left{
    border-radius: 25px 0 0 25px;
}
#upload-photo-section .price-section2{
    border-radius: 0 25px 25px 0;
}
#upload-photo-section .price-section2{
    border-radius: 0 25px 25px 0;
}
#upload-photo-section .price-section1{
    border-radius: 25px 0 0 25px;
}

#upload-photo-section1 .pay-btn{
    border-radius: 50px;
    color: white;
    background-color: #4481ee !important;
    box-shadow: none;
    border: none;
    width: 200px;
    padding: 5px;
    font-size: 20px;
}
#upload-photo-section1 .pay-btn{
    position: absolute;
    top: 90%;
    right: calc(50% - 100px);
}
#upload-photo-section1 .processing-section {
    position: relative;
    margin-bottom: 50px;
}
#upload-photo-section1 {
    position: relative;
}
#upload-photo-section1 .section-inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 20px 0;
}
#upload-photo-section1 #upload-photo-section {
    width: 58%;
}
#upload-photo-section1 .processing-section{
    width: 38%;
}


























@media (max-width: 992px) {
    #upload-photo-section .radios-btn{
        width: 100%;
    }
    #upload-photo-section .personal-info-section{
        width: 100%;
    }
    #upload-photo-section .processing-section-title-inner{
        width: 100%;
    }
    #upload-photo-section .containers {
        padding-bottom: 20px;
    }
    #upload-photo-section .containers2 {
        width: 100%;
        padding: 0;
    }
    #upload-photo-section .paln-renewals{
        flex-wrap: wrap;
        justify-content: center;
    }
    #upload-photo-section1 #upload-photo-section {
        width: 100%;
    }
    #upload-photo-section1 .processing-section{
        width: 100%;
    }
    #upload-photo-section1 .pay-btn{
        top: 100%;
    }
    #upload-photo-section1 .idl-fast hr {
        width: 100%;
        margin: 0 auto;
    }
}
@media (max-width: 768px) {
    #upload-photo-section .usps-tracking{
        display: block;
    }
    #upload-photo-section .custom_radio_btn {
        margin-bottom: 30px;
    }
    #upload-photo-section .usps-tracking{
        padding: 30px;
    }
    #upload-photo-section .custom_radio_btn1{
        justify-content: end;
    }
    #upload-photo-section .option-text{
        width: fit-content;
        margin: 0 !important;
    }
}
@media (max-width: 722px) {
    #upload-photo-section .radios-btn{
        flex-wrap: wrap;
        justify-content: center;
    }
    #upload-photo-section .rabit-img{
        width: auto;
    }
    #upload-photo-section .checkbox-inner{
        width: fit-content;
    }
}
@media (max-width: 600px) {
    #upload-photo-section .digital-bundle-prices{
        display: block;
        margin: 20px 0;
    }
    #upload-photo-section .digital-bundle-prices div{
        margin: auto;
    }
    #upload-photo-section .price-section1{
        border-radius: 20px 20px 0 0;
        border: 2px solid #3ca8df;
        border-bottom-width: 0px;
    }
    #upload-photo-section .price-section2{
        border-radius: 0 0 20px 20px;
        border: 2px solid #3ca8df;
    }
    #upload-photo-section .price-section-select{
        margin: -2px auto !important;
    }
    #upload-photo-section .active_kjndvscn{
        width: 80%;
    }
    #upload-photo-section .price-section2{
        border-radius: 0 0 25px 25px;
    }
    #upload-photo-section .price-section1{
        border-radius: 25px 25px 0 0;
    }
    /* right */
    #upload-photo-section .active-sectionmain-right{
        border-radius: 0 0 25px 25px;
    }
    /* left */
    #upload-photo-section .active-sectionmain-left{
        border-radius: 25px 25px 0 0;
    }
}
@media (max-width: 420px) {
    #upload-photo-section .nav-items{
        border: 2px solid #3ca8df;
        border-radius: 10px;
        overflow: hidden;
        width: 100%;
        text-align: center;
        margin: 0 0 10px 0;
    }
    #upload-photo-section #uncontrolled-tab-example-tab-profile{
        margin: 0 auto;
    }
}
.active-plan {
    background-color: #b5f2f1 !important;
}

#upload-photo-section .active_kjndvscn{
    padding: 70px 20px;
    background-color: white;
    height: 250px;
    border-width: 2px;
    border-radius: 25px;
}

.summary-loader {
    position: absolute;
    top: 33%;
    left: 43%;
}
.unpaid-order-loader {
    position: absolute;
    top: 25%;
    left: 50%;
}
.unpaid-loader {
    position: relative;
    opacity: 0.5;
}
.unpaid-loader-none {
    position: relative;
    opacity: 1;
}
#document-section1 .documents-btn {
    background: #da7db1;
    border: none;
    padding: 10px 20px;
    margin-bottom: 45px;
}
#document-section1 .digital-document p {
    font-size: 23px;
    margin-top: 40px;
    color: white;
    width: 100%;
}
#document-section1 .digital-document {
    background: linear-gradient(270deg, #3ab1a8 0%, #b3e4da 100%);
    width: 100%;
    padding: 0 10px;
    text-align: center;
    min-height: 300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.unpaid-payment-loader {
    position: absolute;
    left: -25%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
}

@media (max-width: 992px){
    .unpaid-payment-loader {
        left: 50%;
    }
}

.driver-license-img-display{
    min-height: 200px;
    max-height: 450px;
    overflow: hidden;
    cursor: move;
    background-image:
            linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
            url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC');
}

.MuiDialogTitle-root {
    flex: 0 0 auto;
    margin: 0;
    padding: 16px 24px;
}

.MuiTypography-h6 {
    font-size: 1.25rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
}

.MuiTypography-root {
    margin: 0;
}

.crop-title {
    display: flex;
    justify-content: space-between;
}

.MuiDialogContent-dividers {
    padding: 16px 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.two-fa-otp {
    display: flex;
    justify-content: center;
    align-items: center;
}
.two-fa-otp-input {
    background: #e6f1fd !important;
    border: 0 !important;
}
.apo-check-box {
    margin-top: 48px;
    margin-bottom: 0 !important;
}
@media (min-width: 992px) and (max-width: 1199px), (max-width: 575px) {
    .apo-check-box {
        margin-top: 0;
        margin-bottom: 15px !important;
    }
}
.pr-0 {
    padding-right: 0 !important;
}